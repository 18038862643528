





























.animation {
    animation: wiggle 2s infinite;
}

@keyframes wiggle {
    0%,
    7% {
        transform: rotateZ(0) rotateY(0);
    }
    15% {
        transform: rotateZ(-30deg) rotateY(-30deg);
    }
    20% {
        transform: rotateZ(15deg) rotateY(15deg);
    }
    25% {
        transform: rotateZ(-15deg) rotateY(-15deg);
    }
    30% {
        transform: rotateZ(7deg) rotateY(7deg);
    }
    35% {
        transform: rotateZ(-4deg) rotateY(-4deg);
    }
    40%,
    100% {
        transform: rotateZ(0) rotateY(0);
    }
}
